import * as React from "react";
import { PageProps } from "gatsby";

import Layout from "@layouts/Layout";
import Seo from "@components/Seo";
import NotFound from "@components/NotFound";

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <>
      <Seo title="Not Found" />
      <NotFound />
    </>
  );
};

export default NotFoundPage;
